@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.root {
	background-color: $background-white;
	color: $text-black;

	@include section-padding;
}
.header h2 {
	text-align: center;

	max-width: 18ch;
	margin-inline: auto;
}

.desktop_only {
	display: block;
	@include sm-down {
		display: none;
	}

	width: 100%;
	max-width: 40rem;
	height: 20rem;

	margin: 4rem auto;

	object-fit: cover;

	border-radius: 0.875rem;

	box-shadow: 0 272px 76px 0 rgba(0, 0, 0, 0), 0 174px 70px 0 rgba(0, 0, 0, 0.01), 0 98px 59px 0 rgba(0, 0, 0, 0.05), 0 44px 44px 0 rgba(0, 0, 0, 0.09), 0 11px 24px 0 rgba(0, 0, 0, 0.1);
}

.mobile_only {
	display: none;
	@include sm-down {
		display: block;
	}

	height: 30rem;
	width: 100%;

	margin: 2rem auto;

	border-radius: 0.875rem;

	object-fit: cover;
}

.content {
	max-width: 60rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 7rem;
	margin: 7rem auto;

	@include sm-down {
		grid-template-columns: 1fr;
		gap: 4rem;
		margin: 4rem auto;
	}
}

.content p {
	color: #a6a6a6;
}

.cta_container {
	display: flex;
	justify-content: center;
}

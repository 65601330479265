@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $background-off-white;
	color: $text-black;

	@include section-padding;
}

.header {
	@include max-width-center;

	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	margin-block-end: 64px;

	gap: 1rem;

	flex-wrap: wrap;

	@include sm-down {
		margin-block-end: 40px;
	}

	h2 {
		max-width: 28ch;
	}
}

.marquee {
	@include section-padding-inverse-x;
}

.marquee_element {
	> div {
		align-items: flex-start;

		@include xs-only {
			--gradient-width: 0px !important;
		}

		> div {
			align-items: flex-start;
		}
	}

	@include xs-only {
		overflow-x: auto !important;
	}
}

.marquee_item {
	background-color: $background-white;
	position: relative;

	> a {
		position: static;
	}

	margin-inline: 10px;

	border-radius: $br-sm;

	box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.04);

	max-width: 300px;
}

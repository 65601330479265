@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	position: relative;
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: #000;
}

.image {
	position: relative;
	width: 100%;

	@include md-down {
		height: 795px;

		img {
			height: 100%;
		}
	}
}

.image_mask_1 {
	@include sm-down {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 200px;

		background: #000;
	}
}

.image_mask_2 {
	@include sm-down {
		position: absolute;
		top: 200px;
		left: 0;
		width: 100%;
		height: 250px;

		background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 64.8%);
	}
}

.content_container {
	position: absolute;
	top: 120px;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	padding: 0 24px;

	display: flex;
	flex-direction: column;
	align-items: center;

	gap: 24px;
}

.title {
	text-align: center;
	color: #fff;
}

.subtitle {
	margin-bottom: 52px;
	text-align: center;
	color: rgba(255, 255, 255, 0.5);
}

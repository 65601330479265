@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/pod-3-fonts';
@import 'styles/colors';

h1.heading_mattress {
	max-width: 15ch;
}

h1.heading_cover {
	max-width: 15ch;
}

.equinox_logo {
	fill: white;
	margin-bottom: 20px;

	:global(.ex) {
		stroke: white;
	}
}

.equinox_footer {
	fill: black;
	margin-bottom: 15px;

	:global(.ex) {
		stroke: black;
	}
}

@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	background-color: #0e0e0e;
	color: $white;

	position: relative;
	min-height: 100vh;

	isolation: isolate;

	@include section-padding;
}

.header_container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	max-width: $max-width;
	margin-inline: auto;

	@include sm-down {
		grid-template-columns: 1fr;
		gap: 0.75rem;
	}
}

.subheader {
	max-width: 45ch;
	text-align: left;
	align-self: end;
	color: #a6a6a6;

	@include sm-down {
		margin-left: 0;
	}
}

.cta {
	margin-top: 2.5rem;
	margin-bottom: 10rem;

	@include sm-down {
		display: none;
	}
}

.header {
	h2 {
		max-width: 24ch;
	}
}

.keyframes {
	padding-top: 40vh;

	@include sm-down {
		padding-top: 4rem;
	}
}

.keyframe_section {
	display: grid;
	max-width: 90rem;

	margin-bottom: 4rem;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr auto;

	justify-items: start;

	&:last-child {
		margin-bottom: 3rem;
	}

	@include md-up {
		margin-bottom: 50vh;
		margin-inline: auto;

		column-gap: 4rem;

		grid-template-columns: repeat(3, minmax(0, 1fr));
		grid-template-rows: auto auto;

		align-items: center;

		h3 {
			grid-column: 1;
			grid-row: 2 / 3;
		}

		.tag {
			grid-column: 1;
			grid-row: 1;
			align-self: end;
		}

		.description {
			grid-column: 3;
			grid-row: 2;
			align-self: start;
		}
	}
}

.keyframe_section h3 {
	text-align: left;

	max-width: 20ch;

	@include md-up {
		max-width: 14ch;
		text-wrap: balance;
		margin-bottom: 2rem;
	}
}

.keyframe_section .tag {
	display: inline-block;

	text-transform: uppercase;

	padding: 0.25rem 0.75rem;
	color: #a6a6a6;

	border-radius: 0.375rem;
	border: 1px solid #a6a6a6;

	@include md-up {
		margin-bottom: 1rem;
	}
}

.keyframe_section .description {
	color: #a6a6a6;

	max-width: 40ch;

	@include md-up {
		max-width: 30ch;
	}
}

.rails {
	position: absolute;

	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	z-index: -1;

	//filter: drop-shadow(0px 47px 103px rgba(173, 173, 173, 0.34)) drop-shadow(0px 188px 188px rgba(173, 173, 173, 0.3)) drop-shadow(0px 423px 254px rgba(173, 173, 173, 0.17))
	//	drop-shadow(0px 752px 301px rgba(173, 173, 173, 0.05)) drop-shadow(0px 1175px 329px rgba(173, 173, 173, 0.01));

	@include sm-down {
		display: none;
	}

	padding-top: 25rem;
	padding-bottom: 8rem;

	div {
		top: 12.5vh;
		position: sticky;
		display: grid;
		justify-items: center;
		align-items: center;

		svg {
			max-height: 75vh;

			@include sm-down {
				margin-top: 10vh;
				height: 90vh !important;
				width: auto !important;
			}

			* {
				filter: none !important;
			}

			g {
				rect {
					display: none;
				}
			}
		}
	}
}

.divider {
	width: 100%;

	@include sm-down {
		border-top: 1px solid $neutral-subdued-3;
	}
}

.mobile_image {
	margin-inline: auto;

	img {
		padding: 4rem 0;
		max-width: 30rem;
		margin-inline: auto;
	}

	@include md-up {
		display: none;
	}
}

.smallprint {
	color: $text-grey-light;
	font-size: 0.675rem;
	position: absolute;
	bottom: $spacing-xx-large-desktop;
	left: 0;
	right: 0;
	text-align: center;
	margin: 0 auto;

	@include xs-only {
		text-align: left;
		padding: 0;
		position: static;
	}
}

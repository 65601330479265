@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;
	overflow: hidden;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;

	h3 {
		@include headline7;
		width: 20ch;
		text-align: center;
		@include sm-down {
			@include headline8;
			text-align: center;
		}
	}
}

.header {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 4rem;

	@include sm-down {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
	}

	h2 {
		@include headline6;
		width: 11ch;

		@include sm-down {
			@include headline7;

			max-width: 300px;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.sub_header {
		@include body4;
		color: #a6a6a6;
		width: 24ch;
		font-size: 1.1rem;

		@include sm-down {
			@include body1;
			font-size: 1.1rem;
			max-width: 300px;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.description {
	@include body4;
	font-size: 0.9375;
	width: 30ch;
	text-align: center;

	@include sm-down {
		width: 24ch;
	}
}

.hero_img {
	img {
		margin: 60px 0 40px;
		height: 312px;

		@include sm-down {
			margin: 45px 0;
			height: auto;
			width: 100vw;
		}
	}
}

.smaller_img {
	img {
		margin-top: 1rem;
		max-width: 350px;
	}
	margin-top: 1rem;
	max-width: 540px;
	@include sm-down {
		max-width: 360px;
		img {
			max-width: 280px;
		}
	}
}

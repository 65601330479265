@import 'styles/mixins/fonts-2023';

.size_guide_entry {
	> p {
		@include body6;
		margin-bottom: 0.5rem;
	}
}

.bed_sizing {
	display: inline-grid;
	grid-template-areas: 'blank width' 'length svg';
	justify-items: center;
	align-items: center;
	text-align: center;
	gap: 0.5rem;

	margin-right: auto;

	color: #797c8e;
}

.bed_svg {
	grid-area: svg;
	svg {
		width: 100%;
		height: 100%;
	}
}

.length {
	grid-area: length;
	@include body7;
}

.width {
	grid-area: width;
	@include body7;
}

@import 'styles/colors';
@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/pod-3-fonts';
@import 'styles/mixins/fonts';
@import 'styles/mixins/fonts-2023';

.image_container {
	margin-top: 60px;
	display: grid;
	grid-template-columns: 1.3fr 0.7fr;
	grid-template-rows: min(313px, 33vh) min(313px, 33vh);
	gap: 30px 30px;
	grid-template-areas:
		'img1 img2'
		'img1 img3';

	@include sm-down {
		gap: 10px 10px;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 0.5fr;
		grid-template-areas:
			'img1 img1'
			'img2 img3';
		margin-top: 60px;
	}

	img {
		object-fit: cover;
		height: 100%;
	}
}

.img1 {
	grid-area: img1;
}

.img2 {
	grid-area: img2;
}

.img3 {
	grid-area: img3;
}

.numtexttitle {
	@include h2-mobile;
	line-height: 140%;
	font-weight: bold;
	max-width: 20ch;
	margin-left: auto;
	margin-right: auto;

	padding: 0 1.5rem;

	@include md-up {
		@include pod-3-header-2;
		max-width: 20ch;
		margin: 0 auto 18px;
	}
}

.numtextsub {
	@include pod-3-body-2;
	line-height: 140%;
	margin-bottom: 0;

	@include sm-down {
		@include pod-3-body-4;
		margin-bottom: 0;
	}
}

.container {
	max-width: 1066px;
	margin-left: auto;
	margin-right: auto;
}

.content_container {
	color: #000;
}

.content_container :global(span.num) {
	background: unset;
	-webkit-background-clip: unset;
	-webkit-text-fill-color: unset;
	background-clip: unset;
	text-fill-color: unset;

	background: linear-gradient(91deg, #014ea8 -14.01%, #013b8b 53.53%, #012a6d 115.67%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.numtext.numtext {
	margin-top: 60px;
	row-gap: 60px;

	@include sm-down {
		flex-direction: column;
	}

	@include xs-only {
		margin-top: 60px;
	}

	> li {
		margin: 0 45px;

		@include sm-down {
			text-align: center;
		}
	}
}

.numtext > li:global(.numtext) {
	@include md-up {
		align-items: flex-start;
	}

	:global(span.text) {
		@include pod-3-header-5;

		@include sm-down {
			@include pod-3-header-7;
		}

		> sup {
			font-weight: 400;
			font-size: 0.5em;
			margin-left: 3px;
		}
	}

	:global(span.num) {
		@include heading2;
		font-size: 4rem;
	}
}

.icons {
	margin-top: 30px;
	display: flex;
	justify-content: space-between;

	@include sm-down {
		flex-direction: column;
	}
}

.icon_item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@include sm-down {
		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}

	img {
		filter: $navy-blue-filter;
		width: 40px;
		height: 40px;

		margin-bottom: 10px;
	}

	h5 {
		@include pod-3-header-4;
		margin-bottom: 10px;
		@include sm-down {
			@include pod-3-header-5;
			margin-bottom: 10px;
		}
	}

	p {
		@include pod-3-body-4;
		@include sm-down {
			@include pod-3-body-4;
		}
	}
}

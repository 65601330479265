@use 'sass:color';
@import 'styles/colors-2023';
@import 'styles/mixins/button-styles-2023';

.base_styles {
	@include button-base;
}

.compact {
	@include button-compact;
}

.large {
	@include button-large;
}

.primary {
	@include button-primary;
}

.secondary {
	@include button-secondary;
}

.outline {
	@include button-outline;
}

.text {
	@include button-text;
}

.loading_spinner {
	height: 24px;
	width: 24px;
	position: relative;

	svg {
		path {
			opacity: 0;
			animation: lds-spinner 1s ease infinite;
		}
	}
}

.empty {
	padding: 0;
	margin: 0;

	color: black;
}

@keyframes lds-spinner {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.icon_span {
	svg {
		height: 1em;
		width: 1em;

		max-width: 4rem;
		max-height: 4rem;
	}
}

@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.benefits_list {
	list-style: none;
	margin: 0;
	padding: 0;

	display: grid;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	gap: 4rem;

	@include sm-down {
		grid-template-columns: 2fr;
		gap: 1.5rem;
		grid-auto-flow: unset;
	}
}

.benefit {
	display: flex;
	gap: 30px;

	p {
		max-width: 24ch;

		&.subcopy {
			color: $text-grey-dark;
		}
	}

	svg {
		margin-bottom: 0.5rem;
	}
}

.benefits_container {
	padding: $spacing-xxx-large-desktop $spacing-xl;
	background: $background-white;
	color: $text-black;

	h2 {
		margin-bottom: 3rem !important;
	}

	@include sm-down {
		padding: 4rem $spacing-large-mobile;
	}

	border-bottom: 1px solid $neutral-grey-1;
}

.wrapper {
	max-width: $max-width;
	margin-inline: auto;

	.heading {
		max-width: 800px;
	}
}

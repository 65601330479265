@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';
@import 'styles/colors-2024';

.inner {
	color: $neutral-black;
	display: grid;
	grid-template-columns: 1fr 1fr;
	max-width: 1280px;
	margin-inline: auto;
	column-gap: 100px;
	padding: 90px 60px;

	&:not(:global(.bg-white)) {
		background-color: $ui-light-grey;
	}

	h2 {
		font-size: 48px;
		margin-bottom: 0.8em;
		font-weight: 700;
		max-width: 485px;
		line-height: 1.1;
	}

	h3 {
		font-size: 36px;
		font-weight: 700;
		color: $brand-eight-blue;
		margin-bottom: 10px;
	}

	h5 {
		font-size: 24px;
		margin-bottom: 2em;
		font-weight: 700;
	}

	.mobile_heading {
		display: none;
	}

	@include sm-down {
		grid-template-columns: 1fr;
		justify-content: center;
		padding: 0;

		.mobile_heading {
			display: block;
			width: 100%;
			text-align: center;
			font-size: 40px;
			background-color: white;

			margin: 0 auto;

			max-width: 10ch;

			line-height: 1.1;
		}

		h2 {
			display: none;
		}

		.text {
			padding: 0 2rem;
			text-align: center;
		}

		h3 {
			font-size: 32px;
		}

		h5 {
			margin-bottom: 30px;
			font-size: 20px;
		}
	}
}

.mobile_heading_container {
	width: 100%;
	background: white;

	padding: 2rem 0;
	margin-bottom: 2rem;
	@include md-up {
		display: none;
	}
}

.image_placeholder {
	padding: 0 3rem;
	max-height: 32rem;
	margin-bottom: 30px;

	@include md-up {
		padding: 0;
		height: 100%;
		margin-bottom: 0;

		img {
			height: 100%;
		}
	}
}

.content {
	padding: 0;
}

.pill {
	border-radius: 5px;
	background: linear-gradient(91deg, rgba(1, 78, 168, 0.05) -14.01%, rgba(1, 59, 139, 0.05) 53.53%, rgba(1, 42, 109, 0.05) 115.67%);
	padding: 6px 10px;
	justify-content: center;
	align-items: center;
	color: $brand-eight-blue;
	font-size: 14px;
	letter-spacing: 0.633px;
	width: auto;
	display: inline-block;
	text-transform: uppercase;
	margin-bottom: 20px;
	font-weight: 700;

	@include sm-down {
		display: none;
	}
}

h5.grayish {
	color: #727272;
	font-size: 18px;
	font-weight: 400;
}

.text_center {
	text-align: center;

	h2 {
		max-width: 18ch;
		margin-left: auto;
		margin-right: auto;
	}
}

.cta {
	width: 100%;

	@include md-up {
		width: auto;
	}

	margin-bottom: 15px;
}

.offer_ends {
	font-size: 12px;
	font-weight: 700;
	color: $brand-eight-blue;
	opacity: 0.7;
}

.affirm_cta {
	margin: 0 1ch;
	cursor: pointer;
	text-decoration: underline;
	color: $neutral-black;
}

.pricing_block {
	display: flex;
	gap: 1.25rem;
	margin: 1rem auto;
}

.price {
}

.sale_amount {
	color: #d9682a;
	@include body7;
	margin-bottom: 0.625rem;
	min-height: 17px;
}

.price {
	@include subheading4;
	margin-bottom: 0.375rem;

	s {
		font-weight: 400;
		color: #797c8e; //TODO OUT OF LIBRARY
	}
}

.price_subtext {
	@include body7;
	opacity: 0.7;
}

.divider {
	width: 1px;
	background-color: $neutral-4;
}

@use 'sass:math';
@import './Type.utils.scss';
@import './Type.tokens.scss';
@import './Type.defaultMargins.scss';

$mobile-breakpoint: math.div(600, 16);
$desktop-breakpoint: math.div(1024, 16);

@mixin responsive-type($font-size, $line-height, $letter-spacing, $mobile-breakpoint, $desktop-breakpoint, $font-weight, $text-transform) {
	font-size: fontSolve($mobile-breakpoint, map-get($font-size, mobile), $desktop-breakpoint, map-get($font-size, desktop));
	line-height: fontSolve($mobile-breakpoint, map-get($line-height, mobile), $desktop-breakpoint, map-get($line-height, desktop));
	letter-spacing: fontSolve($mobile-breakpoint, map-get($letter-spacing, mobile), $desktop-breakpoint, map-get($letter-spacing, desktop));
	font-weight: $font-weight;
	text-transform: $text-transform;
}

// Generate the font classes
@each $name, $styles in $font-styles {
	.#{$name} {
		@include responsive-type(
			map-get($styles, font-size),
			map-get($styles, line-height),
			map-get($styles, letter-spacing),
			$mobile-breakpoint,
			$desktop-breakpoint,
			map-get($styles, font-weight),
			map-get($styles, text-transform)
		);
	}
}

@mixin apply-dynamic-margins($pairs) {
	@each $pair in $pairs {
		.#{map-get($pair, first)} + .#{map-get($pair, second)} {
			margin-top: map-get($pair, margin-top);
		}
	}
}

@include apply-dynamic-margins($spacing-pairs);

.debug {
	position: relative;

	&::after {
		content: attr(data-debug);
		position: absolute;

		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		height: 1rem;

		max-width: inherit;
		text-align: inherit;

		background: rgba(255, 0, 0, 0.5);
		color: white;
		letter-spacing: 0;
		font-size: 0.6rem;
		padding: 0.25rem;
		opacity: 0.85;
	}

	&:hover {
		&::after {
			opacity: 1;
		}
	}
}

@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	color: $text-white;

	text-align: center;

	position: relative;
	z-index: 2;

	$fallback-background: linear-gradient(180deg, #081013 0%, #c2c1bf 100%);

	background-image: url('https://eightsleep.imgix.net/assets/sleep-elixir-waitlist/elixir-new-hero-background.png?h=1000&dpr=2&format=auto'), $fallback-background;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	padding: calc($nav-height-desktop + var(--clean-nav-promo-height, 0px) + 3rem) 3rem 0;

	@include sm-down {
		padding: calc($nav-height-mobile + var(--clean-nav-promo-height, 0px) + 2rem) 2rem 0;
		min-height: 0;
	}

	contain: paint;

	isolation: isolate;

	// height: 100svh;
	height: 100svh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: stretch;
	overflow: hidden;

	> header {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-wrap: nowrap;
	}
}

.image_wrapper {
	position: relative;
	margin-top: 2rem;
	margin-bottom: 2rem;
	max-height: 100%;
	min-height: 0;
	flex: 1 0 0;
	width: 100%;
}

.headline {
	max-width: 15ch;
	margin-inline: auto;
	margin-bottom: 2.5rem;
}

.product_image {
	position: absolute;
	inset: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;

	> img {
		max-height: min(580px, 100%);
		max-width: 520px;
		margin-inline: auto;
		object-fit: contain;

		transform: rotate(var(--gift-rotation)) translate3d(var(--gift-x), var(--gift-y), 0);

		@include sm-down {
			max-height: min(420px, 100%);
			max-width: 300px;
			object-position: center;
		}
	}
}

.top {
	padding: 0;
	border: none;
	font: inherit;
	color: inherit;
	background: none;

	display: flex;
	width: 100%;
	position: relative;
	margin-bottom: 0;

	text-align: left;

	&:hover {
		cursor: pointer;
	}

	img {
		transition: all 0.25s ease-in-out;
	}
}

.accordion_header {
	margin: 0;
}

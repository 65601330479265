@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';
@import 'styles/mixins/fonts-phantom-grotesk';
@import 'styles/mixins/fonts-decorated';

.root {
	background-color: $white;
	color: $black;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-left: 1.5rem;
	padding-right: 1.5rem;

	@include sm-down {
		overflow: auto;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}
}

.eyebrow {
	@include eyebrow1;

	color: #a6a6a6;

	text-transform: uppercase;
	margin: 0 auto 1.5rem;
	@include sm-down {
		@include eyebrow2;
		margin: 0 auto 1.5rem;
	}
}

.header {
	@include headline3;
	margin: 0 auto $spacing-xl;

	@include xs-only {
		@include headline5;
		margin: 0 auto $spacing-md;
	}

	max-width: 492px;
	text-align: center;
}

.big_table {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 2rem;
	max-width: 1410px;
	margin: 0 auto;

	> div {
		border-radius: 8px;
		border: 2px solid #d3d3d3;
		padding: $spacing-lg 35px;
		position: relative;

		&:nth-of-type(3) {
			background: rgba(230, 235, 255, 0.25);
			border-color: #0038ff;
		}
	}

	@include md-up {
		> div {
			&:nth-of-type(2) {
				background: rgba(230, 235, 255, 0.25);
				border-color: #0038ff;
			}
		}
	}

	@include sm-only {
		column-gap: 1.5rem;
		grid-template-columns: 1fr 1fr;
		> div {
			padding: $spacing-md 20px;
			min-width: 263px;

			&:nth-of-type(1) {
				display: none;
			}
		}
	}

	@include xs-only {
		column-gap: 0.8125rem;
		grid-template-columns: 1fr 1fr;

		> div {
			padding: $spacing-md 15px;
			//min-width: calc(80vw - 45px);

			&:nth-of-type(1) {
				display: none;
			}
		}
	}
}

.feature {
	display: grid;
	grid-template-columns: 18px auto;
	column-gap: 0.5rem;
	margin-bottom: 0.5em;

	.icon {
		display: flex;
		margin: 0;
		width: 100%;
	}

	.feature_title {
		margin: 0 0 3px;
		font-size: 1.25rem;
	}

	.feature_description {
		color: #999;
		line-height: 1.1;
		font-size: 1.25rem;
	}

	@include sm-down {
		.feature_title,
		.feature_description {
			font-size: 0.9rem;
		}

		.feature_description {
			margin-bottom: 5px;
		}
	}
}

.podEyebrow {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	background-color: #0038ff;
	color: white;
	border-radius: 5px;
	padding: 6.322px 12.644px;
	text-align: center;
	font-size: 14px;
	letter-spacing: 2px;
	text-transform: uppercase;
	font-weight: 500;
}

.podTitle {
	@include headline5;
	font-size: 2.375rem;
	color: $black;
	text-align: center;
	margin-top: 30px;
	width: calc(100% + 60px);
	margin-left: -30px;

	@include sm-down {
		@include headline5;
		width: 100%;
		margin: 15px 0 0;
	}

	@include xs-only {
		@include headline9;
		font-size: 1rem;
		margin-bottom: 0.25rem;
	}
}

.podSubheader {
	@include body2;
	color: rgba(0, 0, 0, 0.5);
	text-align: center;
	margin: 0 auto 50px;
	width: 199px;
	height: 55px;

	@include sm-down {
		@include body4;
		width: 100%;
		margin: 0 auto 15px;
	}

	@include xs-only {
		@include body6;
		height: auto;
		min-height: 2.8em;
		width: 100%;
		margin: 0 auto 28px;
	}
}

.podImage > img {
	height: 200px;

	@include sm-down {
		height: 150px;
	}
}

.separator {
	background: #dddcdb;
	height: 1px;
	width: 80%;
	margin: 50px auto;

	@include sm-down {
		margin: 20px auto;
	}
}

.podInfoCta {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 50px auto;

	@include sm-down {
		margin: 20px auto;
	}
}

.podInfoPrice {
	@include headline7;
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 0.5rem;

	@include sm-down {
		@include body6;
	}
}

.strikethrough {
	text-decoration: line-through;
	color: #717171;
}

.podFinancing {
	a {
		color: #999;
		@include headline10;
		font-size: 0.75rem;
		// font-weight: 500;
		// line-height: 1;
	}
	color: #999;
	@include headline10;
	font-size: 0.75rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
	flex-direction: column;
	@include sm-down {
		@include body6;
		font-size: 0.625rem;
		a {
			@include body6;
			font-size: 0.625rem;
		}
	}
}
.podFinancingSvg {
	height: 1.8ch;
	transform: translateY(0px);
}
.ctaLabel {
	@include button1;
	@include xs-only {
		@include headline10;
		font-size: 12px;
	}
}

.compareButton {
	padding: 1rem 1.125rem;
	margin-top: 4.2rem;
	@include md-up {
		display: none;
	}
	@include xs-only {
		padding: 1rem;
	}
	span > svg {
		@include md-down {
			margin-left: 0.5rem;
		}
	}
}

.sliderFeatureContainer {
	.sliderDescription,
	.sliderFeature {
		text-align: left;
	}

	.sliderDescription {
		@include body3;
		width: 90%;

		color: #717171;
		padding: 1rem 0;
		display: block;
		padding-left: 32px;

		@include sm-down {
			padding-left: 24px;

			@include body6;
			width: 100%;
		}
	}

	.sliderFeature {
		@include body2;
		display: grid;
		grid-template-columns: 24px auto;
		column-gap: 0.5rem;
		width: 90%;
		@include sm-down {
			grid-template-columns: 16px auto;

			@include body5;
		}
	}
	:global(.arrow_slide_down_down),
	:global(.arrow_slide_down_up) {
		top: 0;
		right: 0;
		bottom: auto;
		@include sm-down {
			width: 12px;
		}
	}

	margin-bottom: 32px;

	@include sm-down {
		margin-bottom: 16px;
	}
}

.fullScreenContent {
	height: 100%;
	padding: 5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: scroll;

	@include sm-down {
		padding: 4rem 2rem;
	}

	@include xs-only {
		padding: 0.5rem;
	}
}

.truemed_logo_small {
	margin-left: 0.25ch;

	img {
		height: 15px;
		@include sm-down {
			height: 10px;
		}
		width: fit-content;
		display: inline;
	}
}

.disclaimer {
	text-align: center;
	display: block;
	margin-top: 16px !important;
	opacity: 0.7;
}

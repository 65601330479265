@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;
}
.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.875rem;
	h3 {
		@include headline7;
		@include sm-down {
			@include headline8;
			width: 20ch;
			text-align: center;
		}
	}
	h2 {
		@include headline6;
		text-align: center;
		width: 13ch;
		@include sm-down {
			@include headline7;
		}
	}
	.normal_text {
		@include body4;
		color: #a6a6a6;
		width: 40ch;
		text-align: center;
		@include sm-down {
			//	font-size: 1.5rem;
			width: 22ch;
		}
	}
}
.header {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 4rem;
	margin-bottom: 6rem;
	@include sm-down {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
	}
	h2 {
		@include headline6;
		width: 11ch;
		text-align: left;
		@include sm-down {
			@include headline7;
			width: 17ch;

			margin: 2rem 0 0;
		}
	}
	.normal_text {
		width: 24ch;
		text-align: left;
		@include sm-down {
			@include body2;
			width: 22ch;
		}
	}
}
.feature_box {
	width: 250px;
	border-radius: 8px;
	border: 2px solid #d3d3d3;
	padding: 2.5rem 1.25rem 3rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 3rem;
}
.sliderFeatureContainer {
	.sliderDescription,
	.sliderFeature {
		@include body5;
		text-align: left;
		width: 18ch;

		margin: 0.25rem 0.75rem 0;
	}

	.sliderDescription {
		display: block;
		color: #717171;
	}
	.sliderFeature {
		display: block;
		margin: 0 5ch 0 auto;
	}
	:global(.arrow_slide_down_down),
	:global(.arrow_slide_down_up) {
		top: 0;
		bottom: auto;
		width: 12px;
	}
	width: 210px;

	h3 {
		@include headline7;
		@include sm-down {
			@include headline8;
			width: auto;
			text-align: center;
		}
	}
}
.side_by_side {
	display: flex;
	flex-direction: row;
	gap: 1.875rem;
	@include sm-down {
		flex-direction: column;
	}
}
.feature_list {
	display: flex;
	flex-direction: column;
	gap: 1.875rem;
}
.comparison_section {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
}
.question_section {
	width: 530px;
	display: flex;
	padding: 2.75rem 2rem 2.75rem 2rem;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 1.875rem;
	border-radius: 8px;
	border: 2px solid #d3d3d3;

	.question {
		color: $black;
	}
	.normal_text {
		width: 100%;
		text-align: left;
	}
	@include sm-down {
		width: 100%;
		border: 0px;
	}
}

.final_section {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.875rem;

	.final_pitch {
		@include headline6;
		width: 24ch;
		text-align: center;
		@include sm-down {
			@include headline7;
			width: 17ch;
		}
	}
	.normal_text {
		width: 42ch;
	}
	@include xs-only {
		.normal_text {
			width: 30ch;
		}
	}
}
.image_section {
	margin-top: -5%;

	.the_img {
		img {
			filter: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.3));
			margin: auto;
			max-width: 400px;
			@include sm-down {
				max-width: 80%;
			}
		}
	}
}

.footer {
	@include body6;
	font-size: 0.625rem;
	color: #a6a6a6;
	max-width: 50ch;
	text-align: left;
	margin-top: 1rem;
}

@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.hero_container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100svh;
	background-color: $black;
	color: $white;
}

.shade {
	> video {
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		inset: 0;
	}
}

.hero_content {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	max-width: 515px;
	gap: 1.5rem;
	justify-content: center;
	position: relative;
	z-index: 2;
	padding: 0 1rem;
}

.hero_button {
	background: white;
	color: black;
	border: 1px solid white;
	border-radius: 5px;
	width: 100%;
	max-width: 220px;
	cursor: pointer;
	padding: 1rem 2rem;
	position: relative;
	text-decoration: none;
	text-align: center;
	letter-spacing: 0.5px;
	white-space: nowrap;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.4;
	transition: all 0.15s;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	&:hover,
	&:focus,
	&:active {
		background: rgba(255, 255, 255, 0.8);
		color: black;
		border: 1px solid white;
	}
}

.anchor_link_wrapper {
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	width: 40px;
	z-index: 1000;
}

.signup_container {
	color: $white;
	background-color: $black;
	padding: 4rem;

	@include sm-down {
		padding: 2rem;
	}
}

.signup_content {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	gap: 4rem;
	max-width: $max-width;
	margin-inline: auto;

	@include sm-down {
		grid-template-columns: 1fr;
		gap: 2rem;
	}
}

.signup_video_container {
	max-width: 100%;
	overflow: hidden;

	> video {
		aspect-ratio: 7 / 6;
		max-height: 600px;
		height: 100%;
		object-position: center;
		object-fit: cover;

		@include sm-down {
			width: 100%;
			aspect-ratio: 1 / 1;
		}
	}
}

.signup_form {
	display: grid;
	gap: 2rem;
	max-width: 610px;
	margin-inline: auto;
	overflow: visible;
	width: 100%;

	@include sm-down {
		max-width: 400px;
		text-align: center;
		margin: 1rem auto 2rem;
	}

	.error {
		color: #e15562;
		font-weight: bold;
	}

	.success {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		row-gap: 40px;
		padding: 40px 20px;
		z-index: 2;
		background-color: #151515;
		color: #fff;
		font-size: 1.3rem;
		font-weight: 500;

		> h6 {
			text-align: left;
			display: flex;
			flex-direction: column;
			gap: 1rem;

			> ul {
				margin: 0;
				padding-left: 20px;
			}
		}
	}

	.form_field {
		max-width: 510px;
	}
}

.charles {
	padding: 10rem 4rem 6rem;
	min-height: 100svh;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	background: url('https://eightsleep.imgix.net/miami-padel/f25816a502eb035170d87c246246d7f1.jpeg?auto=format') center center / cover no-repeat;

	@include sm-down {
		padding: 4rem 2rem;
		justify-content: center;
		align-items: flex-start;
		background-position-y: 33vw;
	}
}

.charles_disclaimer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 2.5rem;
	max-width: 550px;

	@include sm-down {
		align-items: center;
	}
}

button.submit_button.submit_button {
	padding: 1rem 2rem;
	font-size: 1rem;
	max-width: 220px;
	margin-top: 10px;
	width: 100%;

	@include sm-down {
		margin-inline: auto;
	}
}

.form {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
	margin: 0 auto;
	position: relative;

	input {
		width: 100%;
		border-radius: 4px;
		border: 1px solid #fff !important;
		padding: 12px;
		background: transparent;
		color: #fff;

		// placeholder text white
		&::placeholder {
			color: #fff;
		}

		&:focus,
		&:active {
			outline: none;
			border-color: #a0a0a0 !important;
		}
	}
}

.footer {
	background-color: #151515;
	@include section-padding;

	h2 {
		text-align: center;
		margin-bottom: 2.5rem;
	}
}

.footer_content {
	display: flex;
	gap: 1.5rem;
	justify-content: center;

	@include sm-down {
		flex-direction: column;
		align-items: center;
	}

	.box {
		background-color: $black;
		padding: 10px 10px 10px 70px;
		color: $white;
		display: flex;
		gap: 50px;
		align-items: center;
		width: 100%;
		max-width: 440px;

		@include sm-down {
			gap: 30px;
			padding-left: 40px;
		}

		> span {
			color: rgba(255, 255, 255, 0.2);
			font-size: 120px;
			font-weight: 700;
			line-height: 181.816px;
			letter-spacing: 1.697px;
			width: 73px;
			text-align: center;
		}

		> h6 {
			max-width: 185px;
		}
	}
}

.ig_tag {
	text-decoration: underline;
	color: $white;

	&:hover {
		text-decoration: none;
	}
}

@use 'sass:color';
@import 'styles/colors-2023';

@mixin button-base {
	font: inherit;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 140%;
	background: none;
	border: none;
	cursor: pointer;
	text-align: center;

	padding: 10px 30px;

	display: inline-grid;
	grid-template: 'container';
	place-items: center;
	place-content: center;

	transition: all 0.25s ease;

	&:disabled {
		cursor: auto;
	}

	> * {
		grid-area: container;
	}

	> span {
		span {
			display: flex;
			align-items: center;
		}
	}

	svg {
		* {
			transition: all 0.25s ease;
		}
		height: 1.5rem;
	}
}

@mixin button-compact {
	padding: 5px 15px !important;
}

@mixin button-large {
	padding: 15px 30px !important;
}

@mixin button-primary {
	background: $neutral-1;
	color: $neutral-white;
	border-radius: 6px;

	&:not([disabled]) {
		&:hover,
		&:focus-visible {
			background: $neutral-3;
		}

		&:active {
			background: $neutral-1;
		}
	}

	&:disabled {
		background: $neutral-8;
		color: $neutral-4;
		cursor: not-allowed;
	}
}

@mixin button-secondary {
	background: $neutral-white;
	color: $neutral-black;
	border-radius: 6px;

	&:not([disabled]) {
		&:hover,
		&:focus-visible {
			background: $neutral-4;
		}

		&:active {
			background: $neutral-2;
			color: $neutral-white;
		}
	}

	&:disabled {
		background: rgba($neutral-white, 0.6);
		color: $neutral-3;
	}
}

@mixin button-outline {
	background: none;
	color: $neutral-white;
	border-radius: 6px;
	box-shadow: inset $neutral-white 0 0 0 1px;
	position: relative;
	isolation: isolate;
	overflow: hidden;

	&::after {
		content: '';
		width: 200%;
		clip-path: circle(0% at 50% 50%);
		z-index: -1;
		position: absolute;
		background: $neutral-white;
		aspect-ratio: 1 / 1;
		border-radius: 100%;

		transition: background-color 0.25s ease-in-out, clip-path 0.25s ease-in-out;

		pointer-events: none;

		transform: translate3d(var(--x), var(--y), 0);
	}

	&:not([disabled]) {
		&:hover,
		&:focus-visible {
			color: $neutral-black;

			path {
				stroke: $neutral-black;
			}

			&::after {
				clip-path: circle(100% at 50% 50%);
			}
		}

		&:active {
			color: $neutral-white;

			&::after {
				clip-path: circle(100% at 50% 50%);
				background: $neutral-2;
			}
		}
	}

	&:disabled {
		color: $neutral-3;
		box-shadow: none;

		&::after {
			clip-path: circle(100% at 50% 50%);
			background: rgba($neutral-white, 0.6);
		}
	}
}

@mixin button-text {
	background: none;
	color: $brand-secondary;
	position: relative;
	isolation: isolate;
	overflow: hidden;
	padding: 0;

	&:not([disabled]) {
		&:hover,
		&:focus-visible {
			text-decoration: underline;
		}
	}

	&:disabled {
		background: $neutral-1;
		color: $neutral-2;
	}
}

@mixin pg_text {
	font-family: var(--neue-montreal), sans-serif;
	margin-top: 0;
	margin-bottom: 0;
	letter-spacing: 0;
	font-style: normal;
}

@mixin pg_bold {
	@include pg_text;
	font-weight: 700;
}

@mixin pg_medium {
	@include pg_text;
	font-weight: 500;
}

@mixin pg_regular {
	@include pg_text;
	font-weight: 400;
}

/**
  * Large Titles
  */

@mixin pg_large_title_1 {
	@include pg_regular;

	font-size: 10rem;
	line-height: 1.15;
	letter-spacing: -0.1875rem;
}

@mixin pg_large_title_2 {
	@include pg_medium;

	font-size: 6.25rem;
	line-height: 1.1;
	letter-spacing: -0.0625rem;
}

/**
  * Titles
  */

@mixin pg_title {
	@include pg_regular;
	line-height: 1.1;
	letter-spacing: -0.0625rem;
}

@mixin pg_title_1 {
	@include pg_title;
	font-size: 4rem;
}

@mixin pg_title_2 {
	@include pg_title;
	font-size: 3rem;
}

@mixin pg_title_3 {
	@include pg_title;
	font-size: 2.5rem;
}

/**
  * Headings
  */

@mixin pg_heading {
	@include pg_bold;
}

@mixin pg_heading_1 {
	@include pg_heading;
	font-size: 7.5rem;
	line-height: 100%;
}

@mixin pg_heading_2 {
	@include pg_heading;
	font-size: 5rem;
	line-height: 110%;
}

@mixin pg_heading_3 {
	@include pg_heading;
	font-size: 4.5rem;
	line-height: 110%;
}

@mixin pg_heading_4 {
	@include pg_heading;
	font-size: 3.5rem;
	line-height: 110%;
}

@mixin pg_heading_5 {
	@include pg_heading;
	font-size: 2.5rem;
	line-height: 120%;
}

/**
  * Subtitles
  */

@mixin pg_subtitle {
	@include pg_bold;
	line-height: 1.3;
}

@mixin pg_subtitle1 {
	@include pg_subtitle;
	font-size: 2rem;
}

@mixin pg_subtitle2 {
	@include pg_subtitle;
	font-size: 1.5rem;
}

@mixin pg_subtitle3 {
	@include pg_subtitle;
	font-size: 1.25rem;
}

@mixin pg_subtitle4 {
	@include pg_subtitle;
	font-size: 1rem;
	line-height: 140%;
}

@mixin pg_subtitle5 {
	@include pg_subtitle;
	font-size: 0.875rem;
	line-height: 140%;
}

@mixin pg_subtitle6 {
	@include pg_subtitle;
	font-size: 0.75rem;
	line-height: 140%;
}

/**
  * Body
  */

@mixin pg_body {
	@include pg_regular;
	line-height: 1.4;
}

@mixin pg_body1 {
	@include pg_body;
	font-size: 2rem;
	line-height: 130%;
}

@mixin pg_body2 {
	@include pg_body;
	font-size: 1.5rem;
	line-height: 130%;
}

@mixin pg_body3 {
	@include pg_body;
	font-size: 1.25rem;
	line-height: 130%;
}

@mixin pg_body4 {
	@include pg_body;
	font-size: 1.125rem;
}

@mixin pg_body5 {
	@include pg_body;
	font-size: 1rem;
}

@mixin pg_body6 {
	@include pg_body;
	font-size: 0.875rem;
}

@mixin pg_body7 {
	@include pg_body;
	font-size: 0.75rem;
}

@mixin pg_body8 {
	@include pg_body;
	font-size: 0.625rem;
}

/**
  * Eyebrow
  */

@mixin pg_eyebrow_1 {
	@include pg_medium;

	font-size: 1rem;

	line-height: 1.4;
	letter-spacing: 0.125rem;
}

@mixin pg_eyebrow_2 {
	@include pg_medium;

	font-size: 0.75rem;

	line-height: 1.4;
	letter-spacing: 0.125rem;
}

@mixin pg_button1 {
	@include pg_medium;

	font-size: 1rem;
	line-height: 1.4;
}

@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;
	position: relative;
	min-height: 45rem;
	display: flex;
	align-items: stretch;

	@include sm-down {
		min-height: 52rem;
	}
}

.inset_container {
	flex: 1 0 0;

	background: no-repeat 30% 50% url('https://eightsleep.imgix.net/sleep-elixir-waitlist/waitlist-footer-desktop-background.png?&h=2000&dpr=1');
	background-size: cover;
	padding: $spacing-xxx-large-desktop;

	@include sm-down {
		padding: $spacing-xxx-large-mobile 1.5rem 0;
		background-position: center;
		background-image: url('https://eightsleep.imgix.net/sleep-elixir-waitlist/waitlist-footer-mobile-background-v2.png?&h=1000&dpr=2');
	}

	position: relative;
}

.header_wrapper {
	width: 100%;
	height: 100%;
	@include max-width-center;

	display: grid;
	grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

	align-items: center;
	justify-content: center;

	@include sm-down {
		align-items: flex-start;
		justify-content: flex-start;
		row-gap: 2rem;
		grid-template-columns: 1fr;
	}
}

.header {
	border-radius: 8px;
	background: rgba(255, 255, 255, 0.09);
	backdrop-filter: blur(25px);

	display: flex;
	flex-direction: column;
	max-width: 540px;
	width: 100%;
	padding: 60px;
	justify-content: center;
	align-items: center;
	gap: 40px;
	max-height: 300px;

	text-align: center;
	color: $white;

	grid-column: 2;

	@include sm-down {
		margin-inline: auto;
		padding: 1.4rem;
		z-index: 2;
		position: relative;
		gap: 25px;
		height: 100%;
		max-height: 300px;
	}
}

.jar {
	display: none;
	position: absolute;

	@include sm-down {
		display: block;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 2;
	}

	img {
		max-height: 30rem;
	}
}

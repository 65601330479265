@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;

	@include section-padding;

	header {
		max-width: 500px;
	}
}

.content {
	max-width: $max-width;
	margin-inline: auto;

	display: flex;
	justify-content: space-between;
	gap: $spacing-xx-large-desktop;

	@include sm-down {
		flex-direction: column;
		gap: $spacing-xx-large-mobile;
	}
}

.gallery {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	gap: 1.25rem;
	position: relative;

	max-width: 40rem;

	@include xs-only {
		grid-template-columns: 1fr;
		grid-template-rows: repeat(4, minmax(0, 1fr));
		max-height: 100%;
	}
}

$border-radius: 8px;

.card {
	display: grid;
	grid-template-columns: 1fr;

	border-radius: $border-radius;

	position: relative;

	> picture {
		height: 100%;
	}

	img {
		object-position: top center;
		object-fit: cover !important;
		height: 16rem;

		border-top-left-radius: $border-radius;
		border-top-right-radius: $border-radius;
	}

	transition: all 0.2s ease-out;

	&:hover {
		scale: 1.025;
		--shadow-color: 0deg 0% 50%;
		--shadow-elevation-medium: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.36), 0.8px 1.6px 2px -0.8px hsl(var(--shadow-color) / 0.36), 2.1px 4.1px 5.2px -1.7px hsl(var(--shadow-color) / 0.36),
			5px 10px 12.6px -2.5px hsl(var(--shadow-color) / 0.36);

		box-shadow: var(--shadow-elevation-medium);
	}

	@include xs-only {
		grid-template-columns: 1fr 1fr;

		picture {
			position: relative;
		}

		img {
			border-top-right-radius: 0;
			border-bottom-left-radius: $border-radius;

			position: absolute;
		}

		.description {
			border-radius: 0 $border-radius $border-radius 0;
		}

		&:nth-child(odd) {
			> *:first-child {
				order: 2;
			}

			img {
				border-radius: 0 $border-radius $border-radius 0;
			}

			.description {
				border-radius: $border-radius 0 0 $border-radius;
			}
		}
	}
}

.small_print {
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	font-size: 0.625rem;

	grid-column: 1 / -1;
	color: $text-grey-dark;

	@include xs-only {
		margin-bottom: -1rem;
	}
}

.description {
	padding: 1rem 2rem;
	width: 100%;
	background-color: #f9f8f7;

	border-bottom-left-radius: $border-radius;
	border-bottom-right-radius: $border-radius;

	display: flex;
	flex-direction: column;

	.ingredient_name {
		font-weight: 500;
	}

	.additional_info {
		color: $text-grey-dark;

		display: grid;
		grid-template-rows: 0fr;
		transition: grid-template-rows 0.3s, padding 0.3s;

		overflow: hidden;

		> span {
			overflow: hidden;
		}

		&.show {
			grid-template-rows: 1fr;
			padding-top: 10px;
		}

		a {
			color: inherit;
			text-decoration: underline;
		}

		sup {
			font-size: 0.66em;
			line-height: 0;
			margin-inline-start: 0.25em;
		}
	}

	@include xs-only {
		padding: 1.25rem 1rem;
	}
}

.button {
	display: flex;
	padding: 10px;
	align-items: center;
	gap: 10px;

	position: absolute;
	right: 20px;
	top: 20px;

	border-radius: 4px;
	background: rgba(255, 255, 255, 0.2);
	backdrop-filter: blur(4px);
	border: none;
	outline: none;
	cursor: pointer;

	> span {
		width: 16px;
		height: 16px;
	}
}

.bodytext {
	color: $text-grey-dark;
}

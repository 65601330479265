@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $black;
	color: $white;
	min-height: 100dvh;
	position: relative;
	display: flex;
	align-items: center;

	@include md-up {
		&::after {
			content: '';
			position: absolute;
			background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.36) 60.29%, rgba(0, 0, 0, 0) 95.65%);
			backdrop-filter: blur(2px);
			width: 50%;
			height: 100%;
			left: 0;
			top: 0;
			z-index: 1;
		}
	}

	@include sm-down {
		align-items: flex-start;
		flex-direction: column;
		padding-bottom: 2rem;
	}
}

.image {
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	z-index: 0;

	img {
		object-position: right center;
		height: 100%;
	}
}

.content {
	@include section-padding;
	position: relative;
	z-index: 2;

	width: 100%;
	@include max-width-center;

	> header {
		max-width: 450px;
		display: flex;
		flex-direction: column;
		gap: 2rem;

		@include sm-down {
			gap: 1rem;
		}
	}

	> p {
		margin: 0;
	}

	@include sm-down {
		margin: 0 auto;
	}
}

.widget {
	position: absolute;
	bottom: $spacing-xx-large-desktop;
	right: $spacing-xx-large-desktop;

	border-radius: 14.203px;
	border: 0.888px solid rgba(255, 255, 255, 0.1);
	background: rgba(0, 0, 0, 0.42);
	box-shadow: 1.775px 1.775px 56.811px 0 rgba(0, 0, 0, 0.55);
	backdrop-filter: blur(5px);

	width: 320px;
	display: flex;
	flex-direction: column;

	picture {
		padding: 2.5rem 4rem;
	}

	@include sm-down {
		position: relative;
		margin: 0 auto;
		inset: 0;
		z-index: 3;
	}
}

.countup {
	font-size: 3rem;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	right: 0;
	font-weight: 500;
	line-height: 1;
	text-align: center;
}

.widget_text {
	position: absolute;
	bottom: 2.5rem;
	left: 0;
	right: 0;
	font-size: 0.8rem;
	display: flex;
	flex-direction: column;
	row-gap: 15px;
	align-items: center;
	justify-content: center;

	.desc {
		text-transform: uppercase;
		text-align: center;
		color: $white;
		font-weight: 500;
		letter-spacing: 2.52px;
	}

	.green {
		border-radius: 2px;
		background: rgba(93, 202, 195, 0.1);
		display: inline-flex;
		padding: 3px 8px;
		align-items: flex-start;
		color: #5dcac3;
		font-weight: 500;
	}
}

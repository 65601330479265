@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $background-subdued;
	color: $text-white;

	position: relative;
	display: flex;
	justify-content: flex-end;
	padding: 10rem 0;

	@include sm-down {
		padding: 4rem 0 6rem;
	}
}

.content_wrapper {
	//min-height: 53rem;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: relative;
	z-index: 1;
	@include max-width-center;

	@include sm-down {
		justify-content: center;
		align-items: flex-start;
		min-height: max(145vw, 45rem);
	}

	@include xs-only {
		min-height: max(170vw, 45rem);
	}
}

.background_img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	img {
		height: 100%;
	}

	@include sm-down {
		img {
			object-position: bottom !important;
		}

		&::after {
			background-color: black;
			opacity: 0.1;
			z-index: 1;
			pointer-events: none;
			content: '';
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
		}
	}
}

.benefits_container {
	margin-left: 5rem;
	margin-right: 5rem;
	max-width: 440px;

	@include sm-down {
		margin-left: 0;
		margin-right: 0;
		padding: 0 1.5rem;
	}
}

.benefits {
	margin-top: 2.5rem;

	@include sm-down {
		margin-top: 1.5rem;
	}

	li {
		margin-bottom: 1rem;
		display: flex;
		align-items: center;
		gap: 0.75rem;
	}
}

.logos {
	display: flex;
	column-gap: 50px;
	margin: 0;

	img {
		height: 84px;
		width: auto;
	}

	@include sm-down {
		justify-content: space-between;
		column-gap: 0;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 0 1.5rem;

		max-width: 440px;

		margin-inline: auto;
	}
}

@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.shop_section {
	background-color: $white;
	color: $black;
	min-height: calc(100dvh - 113px - var(--promo-bar-translate));

	display: grid;
	grid-template-columns: 1fr 1fr;

	@include sm-down {
		grid-template-columns: 1fr;
	}
}

.shop_section__content {
	padding: $spacing-xx-large-desktop 5rem;
	max-width: 800px;
	margin: 0 auto;
	width: 100%;

	@include sm-down {
		padding: $spacing-xx-large-mobile $spacing-large-mobile;
	}
}

.atc_button {
	width: 100%;
	min-height: 54px;
	margin-top: 2rem;
	margin-bottom: 2rem;
	font-size: 1.125rem;
}

.product_title.product_title {
	margin-bottom: 1rem;
}

.product_prices.product_prices {
	margin-bottom: 0;
	padding-bottom: 1.2rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	font-weight: 500;

	border-bottom: 1px solid $text-grey-light;
}

.strikethrough {
	color: #b3b3b3;
	text-decoration: line-through;
}

.selection_container {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.card_container {
	color: $text-grey-dark;

	border-radius: 0.5rem;
	box-shadow: #d3d3d3 0 0 0 1px inset;

	transition: all 0.2s ease-out;
	position: relative;

	height: 100%;

	padding: 1.5rem;
	min-height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	&:hover {
		background-color: rgba(230, 235, 255, 0.75);
	}

	&:focus-visible,
	&.selected {
		background-color: rgba(230, 235, 255, 0.75);
		color: $black;
	}

	@include xs-only {
		padding: 1rem;
	}
}

.checklist {
	list-style: none;
	margin: 10px 0 0;

	display: flex;
	flex-direction: column;
	gap: 0.25rem;

	li {
		display: grid;
		grid-template-columns: auto 1fr;
		gap: 0.25rem;
		align-items: start;

		color: $black;

		> p {
			word-wrap: break-word;
			hyphens: auto;
			-webkit-hyphens: auto;
		}
	}
}

.card_selection__title {
	display: flex;
	align-items: center;
	gap: 10px;
}

.card_price.card_price {
	display: flex;
	align-items: center;
	gap: 8px;
	color: $black;
	margin: 0 0 0 auto;
	margin-block: 0;

	> s {
		color: $text-grey-dark;
	}

	> strong {
		font-weight: 500;
	}
}

.card_features {
	display: grid;
	grid-template-rows: repeat(3, 1fr);
	gap: 0.5rem;
}

.card_feature {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	height: 25px;

	> p {
		line-height: 1;
	}
}

.tabs_wrapper {
	width: 100%;
}

.tabs_buttons {
	display: flex;
	gap: 1.5rem;

	@include xs-only {
		gap: 1rem;
	}
}

.tab_button {
	padding: 1rem 0 0.3rem;
	background: none;
	border: none;
	cursor: pointer;
	color: #000;
	border-bottom: 2px solid transparent;
	transition: all 0.2s ease;

	&:hover {
		color: #000;
	}

	&.active {
		color: #000;
		border-bottom-color: #000;
	}
}

.tab_content {
	padding: 1rem 0 1.7rem;
	color: $text-grey-dark;
	line-height: 1;
}

.carousel {
	@include sm-up {
		overflow: hidden;
		height: calc(100dvh - 113px - var(--promo-bar-translate));
		position: sticky;
		top: calc(112px + var(--promo-bar-translate));
		align-self: start;
		transition-property: top height;
		transition-duration: 0.5s;
		transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
	}

	@include xs-only {
		aspect-ratio: 1;
	}

	width: 100%;

	picture > img {
		object-position: top center;
	}

	button picture > img {
		object-position: center;
	}

	@include xs-only {
		:global(ul[data-use-dots='false']) {
			width: auto;
			left: 50%;
			transform: translateX(-50%);
		}
	}
}

.eyebrow.eyebrow {
	font-weight: 500;

	@include xs-only {
		padding: 0.5rem;
		font-size: 0.6rem;
	}
}

.waitlist_form {
	margin-top: 2rem;
	border-top: 1px solid $text-grey-light;
	padding: 2rem 0;
	display: flex;
	flex-direction: column;
	gap: 1.3rem;

	.email_label {
		margin: 0;
		color: $text-grey-dark;
	}

	.waitlist_input {
		border-radius: 4px;
		border: 1px solid $text-grey-light;
		background: $white;
		padding: 12px;
		height: 44px;
	}

	.waitlist_radio_group {
		display: flex;
		gap: 1rem;

		@include xs-only {
			flex-direction: column;
		}
	}

	.waitlist_radio_label {
		display: flex;
		align-items: center;
		gap: 0.5rem;

		> input[type='radio'] {
			margin: 0;
			width: 18px;
			height: 18px;
			appearance: none;
			border: 2px solid $text-grey-dark;
			border-radius: 50%;
			outline: none;
			transition: border-color 0.2s ease;

			&:checked {
				border-color: black;
				background-color: white;
				box-shadow: inset 0 0 0 4px black;
			}

			&:checked + span {
				color: $black;
			}
		}

		> span {
			color: $text-grey-dark;
			line-height: 1.5;
			transition: color 0.2s ease;
		}
	}

	.waitlist_submit {
		margin-top: 1rem;
		padding: 1rem 2rem;
		font-size: 1.1rem;
	}

	.success_message {
		padding: 2rem 0;
		text-align: center;
		max-width: 40ch;
		margin-inline: auto;
		line-height: 1.3;
	}

	.error_message {
		color: $text-error-red-dark-mode;
		font-weight: 500;
		text-align: center;
	}
}

.blurb_container {
	display: block;
	padding: 2rem 0 0;
	margin-top: 2rem;
	border-top: 1px solid $neutral-grey-2;
	color: $black;
	text-decoration: none;

	&:hover,
	&:focus-visible {
		text-decoration: none;

		.blurb_content {
			text-decoration: underline;
		}
	}
}

.blurb_content {
	margin-top: 1.5rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1.5rem;

	img {
		background-color: $off-white;
		border-radius: 4px;
	}

	.blurb_description {
		margin-top: 5px;
		color: $text-grey-dark;
	}
}

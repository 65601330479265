@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	position: relative;

	display: inline-grid;

	grid-template-areas: 'center';
	justify-items: center;

	> * {
		grid-area: center;
	}
}

.float {
	position: absolute;
	user-select: none;
	pointer-events: none;
}

.final_anchor {
	color: transparent;
}

.wheel {
	position: absolute;

	user-select: none;
	pointer-events: none;

	height: 1.3em;
	overflow: hidden;
	align-self: center;

	mask-image: linear-gradient(0deg, transparent 0%, black 10%, black 90%, transparent 100%);

	display: grid;
	justify-items: center;

	> * {
		grid-area: center;
	}
}

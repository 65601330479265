@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;

	@include section-padding;
}

.container_inner {
	max-width: $max-width;
	margin-inline: auto;
}

.content {
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.25rem;
}

.heading.heading {
	max-width: 16ch;
	margin-bottom: 3rem;

	@include sm-down {
		margin-bottom: 2rem;
	}
}

.stats {
	gap: 20px;
	display: grid;

	img {
		border-radius: 5px;

		@include sm-down {
			min-height: 220px;
			object-fit: cover !important;
		}
	}

	@include md-up {
		grid-template-columns: 1fr 3fr;

		&:first-child {
			grid-auto-flow: column;
			grid-template-columns: 3fr 1fr;

			> *:nth-child(1) {
				grid-column: 2;
			}

			> *:nth-child(2) {
				grid-column: 1;
			}
		}
	}
}

.stat_wrapper {
	background-color: #f9f8f7;
	padding: 1.25rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	row-gap: 1rem;
	border-radius: 5px;

	@include sm-down {
		flex-direction: row;
		align-items: flex-end;
		justify-content: flex-start;
		column-gap: 10px;
	}

	contain: paint;
}

.stat {
	vertical-align: text-bottom;
	display: flex;
	align-items: flex-end;
	gap: 20px;

	> span {
		line-height: 0.8;
	}

	.megatitle {
		font-weight: 400;
		font-size: 7.5rem;
	}

	.stat_percent {
		font-size: 4rem;
		font-weight: 400;
	}

	@include sm-down {
		gap: 10px;

		.megatitle {
			font-size: 6rem;
		}

		.stat_percent {
			font-size: 3rem;
		}
	}
}

.stat_text {
	color: $text-grey-dark;
	line-height: 1.2;

	@include sm-down {
		margin-bottom: -4px;
	}
}

.small_print.small_print {
	font-size: 0.625rem;
	margin-top: $spacing-xx-large-desktop;

	@include sm-down {
		margin-top: $spacing-xx-large-mobile;
	}
}

@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $background-white;
	color: $text-black;
	@include section-padding;
	overflow: hidden;
}

.wrapper {
	@include max-width-center;
}

.header {
	@include max-width-center;
	margin-block-end: 4rem;
}

.headline {
	text-align: center;
	max-width: 25ch;
	margin-inline: auto;
}

.video_grid_mobile {
	display: flex;
	gap: 20px;
	max-width: 1440px;
	margin: 0 auto;

	padding: 0;
	list-style: none;

	@include md-up {
		display: none;
	}
}

.video_grid_desktop {
	display: flex;
	gap: 20px;
	max-width: 1440px;
	margin: 0 auto;

	padding: 0;
	list-style: none;

	@include sm-down {
		display: none;
	}
}

.video_grid_item {
	flex: 1;
	min-width: 225px;
}

.videoWrapper {
	position: relative;
	aspect-ratio: 9/16;
	overflow: hidden;
	border-radius: $br-sm;
}

.video {
	width: 100%;
	height: 100%;
	object-fit: cover;
	pointer-events: auto;
}

.posterImage {
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: 1;
}

.overlay {
	position: absolute;
	inset: 0;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 40.63%, #000 94%);
	pointer-events: none;
	z-index: 1;
}

.playButton {
	position: absolute !important;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 3;
	padding: 1.5rem;
	border-radius: 50%;
}

.muteButton {
	position: absolute !important;
	top: 1rem;
	right: 1rem;
	z-index: 3;
	padding: 0.5rem;
	border-radius: 50%;
}

.play_button_inner {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 64px;
	height: 64px;
	border-radius: 50%;
	background: linear-gradient(135deg, rgba(125, 125, 125, 0.7) 0%, rgba(255, 255, 255, 0) 100%);
	backdrop-filter: blur(20.85714340209961px);

	svg {
		transform: translateX(5%); //optical centering
		width: 24px;
		height: 24px;
		color: $white;
	}
}

.expertInfo {
	position: absolute;
	bottom: 1.5rem;
	left: 1.5rem;
	z-index: 2;
}

.expertName {
	color: $white;
}

.expertTitle {
	color: $text-grey-light;
}

@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $background-black;
	color: $text-white;

	@include section_padding;

	text-align: center;

	contain: paint;
}

.lottie_wrapper {
	position: relative;
	height: 1600px;
}

.lottie_container {
	position: sticky;
	top: 0;
	margin-top: 4rem;
	display: flex;
	justify-content: center;
	align-items: center;

	div {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	svg {
		max-width: 800px;
		margin-inline: auto;
	}
}

.heading {
	max-width: 500px;
	margin-inline: auto;
	margin-bottom: $spacing-xx-large-desktop;

	> span {
		background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.7) 70%);
		-webkit-background-clip: text;
		background-clip: text;
		color: transparent;
	}

	em {
		font-size: min(9.5rem, 25vw);
		font-style: normal;
		line-height: 1.2;
		text-shadow: -3px 0 25px #9ab6f0;
		display: flex;
		align-items: center;
		justify-content: center;

		> span {
			font-size: 0.7em;
		}
	}
}

.subheader {
	color: $text-grey-light;
	max-width: 650px;
	margin-inline: auto;
	//margin-bottom: 7rem;
}

.cards {
	position: sticky;
	top: calc(50vh - 250px); // assuming the height of the container is around 500px
	z-index: 1;
	width: 100%;

	display: grid;
	place-content: center;

	> * {
		grid-area: 1 / 1;
	}
}

.card {
	border-radius: 8px;
	border: 1px solid rgba(255, 255, 255, 0.1);
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(5px);
	display: flex;
	width: 100%;
	margin: 0 auto;
	max-width: 400px;
	padding: 40px 25px;
	flex-direction: column;
	align-items: center;
	gap: 40px;
	opacity: 0;

	// scale: 1.1;
	// transform: translateY(-50px);
	transition: opacity 0.5s ease-in-out, scale 0.5s ease-in-out, transform 0.5s ease-in-out;

	h5 {
		margin: 0;
		display: block;
		width: 100%;
		text-align: center;
		line-height: 1;
	}

	&.active {
		opacity: 1;
		scale: 1;
		transform: translateY(0);
	}

	.image {
		position: relative;
		width: 240px;
		height: 250px;

		> svg {
			width: 240px;
			height: 250px;
		}

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 34px;
			height: 80px;
		}
	}
}

.sentinel {
	height: 50vh;
	opacity: 0;
	pointer-events: none;
}

@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';

.slider {
	:global(.slick-dots) {
		display: flex !important;
		margin: 0;
		position: relative;
		justify-content: center;
		height: 2rem;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		list-style: none;

		:global(.slick-active) {
			button {
				opacity: 1;
			}
		}

		button {
			background: $neutral-white;
			padding: 0;
			width: 0.625rem;
			height: 0.625rem;
			position: relative;
			font-size: 0;
			border: none;
			margin: 0 0.5rem;
			cursor: pointer;
			border-radius: 100%;
			opacity: 0.4;

			transition: opacity 0.25s ease;
		}
	}
}

.slider_light {
	:global(.slick-dots) {
		button {
			background: $neutral-black;
		}
	}
}

.arrow {
	width: 2rem;
	height: 2rem;
	border: none;
	background: transparent;
	position: absolute;
	cursor: pointer;
	bottom: 0;
	left: 0;
	padding: 0;
	z-index: 1;

	@include md-up {
		bottom: 50%;
		transform: translateY(50%);
	}
}

.arrow_right {
	right: 0;
	left: auto;
}

@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts_legacy';

.num_text {
	// display: grid;
	// grid-gap: 20px;
	// grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin: 0;
	padding: 0;

	font-weight: bold;

	list-style-type: none;
	row-gap: 20px;

	.item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 0px 50px;
	}
	:global(.text) {
		display: block;

		font-size: 18px;
		line-height: 1.4;
		@include md-up {
			font-size: 24px;
		}
	}
	:global(.num) {
		display: block;

		color: $brand-primary;

		font-size: 32px;
		line-height: 1.4;

		background: -webkit-linear-gradient(#1862ff, #002173);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		@include md-up {
			font-size: 68px;
		}
	}
}

@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.performers_list {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-auto-rows: 1fr;
	column-gap: 24px;
	row-gap: 100px;
	max-width: 100rem;
	margin: 0 auto;

	@include sm-down {
		display: flex;
		flex-direction: column;
		gap: 40px;
	}
}
